button {
  cursor: pointer;
}
.md_container_xx::-webkit-scrollbar {
  width: 0px !important;
  display: none !important                                                                                                                                                                                     ;
}
.md_container_xx::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.md_container_xx::-webkit-scrollbar-thumb {
  background: #888;
}
.openHotspotInstruction {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 9999;
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
  /* padding-top: 4rem; */
  place-items: center;
  display: none;
}
.openHotspotInstructions {
  transform: scale(0);
  transition: all 0.3s;
  /* align-self: center; */
}
.openHotspotInstruction.show {
  display: grid;
}

.openHotspotInstruction.show .hotspotInstructions {
  transform: scale(1);
}
.titleInput {
  /* width: 100%; */
  /* box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);*/
  border-radius: 15px;
  /* padding: 2rem 1rem; */
  border: 0;
  outline: none;
  color: #000;
  font-size: 1.5rem;
  font-weight: 500;
  display: inline-block;
}
.titleInput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */

  color: #000;
  font-size: 1.5rem;
  font-weight: 500;
}

.titleInput:-ms-input-placeholder {
  color: #000;
  font-size: 1.5rem;
  font-weight: 500;
}

.titleInput::-ms-input-placeholder {
  color: #000;
  font-size: 1.5rem;
  font-weight: 500;
}
.hotspotBtn {
  background: white;
  border: 0;
  outline: none;
  flex-grow: 0.5;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15;
  padding: 1rem;
  font-size: 25;
  font-weight: 600;
  color: #646464;
  text-transform: uppercase;
}

.btnCommonStyles {
  border: 0;
  outline: none;
  background: #ebebeb;
  border-radius: 15px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 48px;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.18);
  background: #fff;
}

.btnCommonStyles:hover {
  /* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); */
  /* box-shadow: none; */
  background: #ebebeb;
  /* color: #000;btnCommonStyles */
}
